<template>
  <div>
    <b-modal id="modal-delete" size="md" title="Remove" hide-footer no-close-on-backdrop>
      <p class="font-size-18 text-black">Are you sure you want to delete this data? </p>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" @click="deletePackageSettings">Delete</b-button>
      </div>
    </b-modal>
    <b-row>
      <!-- Start Table -->
      <b-col lg="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">All Classification</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else striped responsive :fields="columns"  :items="allClassefications" class="mb-0 table-borderless"
                     sort-icon-left primary-key="name" id="my-table" :tbody-transition-props="transProps">
              <template v-slot:cell(is_active)="data3">
                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" >
                  <div class="custom-switch-inner">
                    <input type="checkbox" class="custom-control-input bg-primary" v-model="data3.item.is_active" :id="'customSwitch-11'+data3.item.id"   @change="switchStatus(data3.item.id)" >
                    <label class="custom-control-label" :for="'customSwitch-11'+data3.item.id" data-on-label="on" data-off-label="off">
                    </label>
                  </div>
                </div>
              </template>
              <template v-slot:cell(actions)="data1">
                <b-button variant=" iq-bg-danger" size="sm" @click="setDeletedId(data1.item.id)"><i class="ri-delete-bin-line m-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <!-- End Table -->

      <b-col lg="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Classification</h4>
          </template>
          <template v-slot:body>
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(addClassification)">
                <b-row>
                  <b-col md="12" class="mb-2">
                    <input-form v-model="classification.title" name='Title' label="Title"
                                validate="required"></input-form>
                  </b-col>
                  <b-col md="12" class="mb-2">
                    <div class="d-flex  mt-4 align-items-center justify-content-center">
                      <b-button  class="container_button_blue slideNextArrow" type="submit" variant="primary"
                                 v-if="!loadingButton">
                        Save
                      </b-button>
                      <b-button class="container_button_blue slideNextArrow" variant="primary" v-else>
                        <spinner-loading text="Saving" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import settingService from '../services/settings'
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      columns: [
        { label: 'Title', key: 'title', class: 'text-left' },
        { label: 'Status', key: 'is_active', class: 'text-left' },
        { label: 'Actions', key: 'actions', class: 'text-left' }
      ],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      loadTable: false,
      allClassefications: [],
      deleteId: '',
      classification: {
        title: '',
        is_active: true
      }
    }
  },
  methods: {
    getAllClassefications () {
      settingService.getAllClassefications().then(res => {
        this.allClassefications = res.data.data
        this.pagination = res.data.meta
      })
    },
    setDeletedId (id) {
      this.deleteId = id
      this.$bvModal.show('modal-delete')
    },
    addClassification () {
      this.loadingButton = true
      settingService.addNewClassefications(this.classification).then(res => {
        core.showSnackbar('success', res.data.message)
        this.classification.title = ''
        this.getAllClassefications()
      }).finally(() => {
        this.loadingButton = false
      })
    },
    deletePackageSettings () {
      settingService.deleteClassefications(this.deleteId).then(res => {
        core.showSnackbar('success', res.data.message)
        this.getAllClassefications()
        this.$bvModal.hide('modal-delete')
      })
    },
    switchStatus (id) {
      settingService.switchStatus(id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.getAllClassefications()
        this.$bvModal.hide('modal-delete')
      })
    }
  },
  created () {
    this.getAllClassefications()
  }
}
</script>
<style>

</style>
